.react-chatbot-kit-chat-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.react-chatbot-kit-chat-inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

.react-chatbot-kit-chat-message-container {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 17.5px 10px 17.5px;
    box-sizing: border-box;
    width: 100%;
    max-height: 90%;
}

.react-chatbot-kit-chat-input-container {
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #d8d8d8;
    position: relative;
}

.react-chatbot-kit-chat-bot-message-container {
    margin-bottom: 2px;
}
.react-chatbot-kit-user-chat-message-container {
    margin-left: 15px;
}
