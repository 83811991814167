.inline-table thead,
.inline-table tbody,
.inline-table tr {
    display: contents;
}

.inline-table th {
    position: relative;
    padding: 0px;
    padding-left: 1em;
}

.inline-table th,
.inline-table td {
    text-align: left;
    /* padding: 16px 20px; */
    min-width: 100px;
    /* min-height: 53px; */
}

.inline-table td {
    padding-left: 0;
}

.inline-table th span,
.inline-table .td-data div:not(.immune-to-inline-table-styles div),
.inline-table .td-data span:not(.immune-to-inline-table-styles span) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    text-align: left;
}

.resize-handle {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 7px;
    right: 0;
    top: 0;
    z-index: 1;
    border-right: 2px solid transparent;
}

.resize-handle:hover {
    border-color: #ccc;
}

.resize-handle.active {
    border-color: #517ea5;
}
